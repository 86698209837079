import React, { useEffect, useState } from "react";
import { ITour } from "../eurocuppage/EurocupPage";
import { EuroTable } from "../../components/tables/euro/tables/EuroTable";
import { EuroMatchesShow } from "../../components/tables/euro/matches/EuroMatchesShow";
import { ForecastList } from "../../components/tables/euro/forecasts/ForecastsList";
import { EuroForecastForm } from "../../components/forms/forms";
import { getKHLMatches } from "../../api/matchesApi";
import logo from '../../assets/khl_logo.png'

export const KhlPage = () => {
  const [data, setData] = useState<ITour>();

  useEffect(() => {
    const getData = async () => {
      const response = await getKHLMatches();
      setData(response);
    };
    getData();
  }, []);

  console.log(data)

  const doubleMatch: number[] = [];
  !!data?.tour.matches ||
    data?.tour.matches?.map(
      (el, index) => el.is_double && doubleMatch.push(index)
    );

  return (
    <>
      {" "}
      {data ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <div>
            <EuroTable data={data.tour.table} />
          </div>
          {!data.tour.matches ? <h2>Съебался, первый матч 03.09</h2> : (
            <div>
              <EuroMatchesShow matches={data.tour.matches} />
            </div>
          )}
          {!data.tour.forecasts ? null : (
            <div>
              <ForecastList
                double_match={doubleMatch}
                forecasts={data.tour.forecasts}
              />
            </div>
          )}
          <div>
            {!data.canMakeForecast ? null : (
              <EuroForecastForm khl={true} matches={data.tour.matches} />
            )}
          </div>
        </div>
      ) : (
        <>No data</>
      )}
    </>
  );
};
